import {useTranslation} from "react-i18next";
import useToken from "../../hooks/useToken";
import {Controller, useForm} from "react-hook-form";
import {Button, Form, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTiersPayant} from "../../context/TiersPayantContext";
import Select from "react-select";
import {usePatients} from "../../hooks/api/patient";

const apiURL = process.env.REACT_APP_API_URL

const options = [
    { label: 'Facture', value: 'invoice' },
    { label: 'Carte mutuel', value: 'insurance_card' },
    { label: 'Prise en charge', value: 'medical_care' },
    { label: 'Décompte RO', value: 'mandatory_reimbursement' },
    { label: 'Justificatif de réglément', value: 'proof_of_payment' },
    { label: 'Autre', value: 'other' }
]

const PatientFiles = ({tiersPayant, handleClose}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {loading, apiErrors, addPatientFile, resetApiErrors} = useTiersPayant()

    let { id } = useParams();

    const {token} = useToken()
    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
        control,
        setValue,
        setError,
    } = useForm({})

    const onSubmit = (inputs) => {
        addPatientFile(id, inputs)
    }

    return (
        <div>
            <table className="table table-hover table-striped">
                <thead>
                    <tr>
                        <th scope="col">{t('patient.document.list.name')}</th>
                        <th scope="col">{t('patient.document.list.type')}</th>
                        <th scope="col">{t('patient.document.list.date')}</th>
                        <th scope="col">{t('patient.document.list.invoice')}</th>
                    </tr>
                </thead>
                <tbody>
                {tiersPayant && Object.keys(tiersPayant.patient.files).length > 0 && tiersPayant.patient.files.map((file, key) => (
                    <tr key={key}>
                        <td>
                            <a target="_blank"
                               href={`${apiURL}/api/patient-file/${file.id}?token=${token}`}>{file.name}</a>
                        </td>
                        <td>{t(`patient.document.type.${file.type}`)}</td>
                        <td>{file.createdAt}</td>
                        <td className="text-center" style={{color:'green', fontSize: '13px'}}>
                            {tiersPayant.id == file.tiersPayantId && <i className="fa-solid fa-check"></i>}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3" controlId="patient.file.name">
                        <Form.Label>{t('patient.document.form.name.label')}</Form.Label>
                        <Form.Control
                            type="file"
                            size="lg"
                            {...register("name", {
                                required: 'error-empty',
                            })}
                            //disabled={loading}
                            isInvalid={formErrors && formErrors.name}
                        />
                        {formErrors && formErrors.name && (
                            <Form.Control.Feedback type="invalid">
                                {t(`patient.document.form.name.errors.${formErrors.name.message}`)}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="patient.file.type">
                        <Form.Label>{t('patient.document.form.type.label')}</Form.Label>
                        <Controller
                            control={control}
                            render={({field, value, name, ref}) => (
                                <Select
                                    name={field.name}
                                    placeholder={t('patient.document.form.type.place-holder')}
                                    inputRef={field.ref}
                                    options={options}
                                    value={options.find((c) => c.value === field.value)}
                                    onChange={val => {
                                        field.onChange(val.value)
                                    }}
                                    isInvalid={formErrors && formErrors.type}
                                    className={`p-0 form-control form-control-lg ${formErrors && formErrors.type ? ' is-invalid' : ''}`}
                                />)
                            }
                            size="lg"
                            {...register("type", {
                                required: 'error-empty',
                            })}
                        />
                        {formErrors && formErrors.type && (
                            <Form.Control.Feedback type="invalid">
                                {t(`patient.document.form.type.errors.${formErrors.type.message}`)}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Form>
            <Modal.Footer>
            <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        {t('tiers-payant.manage.button.cancel')}
                    </Button>
                    <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                        {t('tiers-payant.manage.button.next')}
                    </Button>
                </Modal.Footer>
        </div>
)
}

export default PatientFiles