import React from "react";

const ListBottomPager = ({metaData, handlePage}) => {
    if (!metaData) {
        return <></>
    }
    return (
        <div className="row">
            <div className="col-sm-12 col-md-5">
                <div className="dataTables_info" id="datatables-clients_info" role="status" aria-live="polite">
                    {metaData.nbTotal} résultats - Page {metaData.currentPage} sur {metaData.nbMaxPage}
                </div>
            </div>
            <div className="col-sm-12 col-md-7">
                <div className="dataTables_paginate paging_simple_numbers" id="datatables-clients_paginate">
                    <ul className="pagination">
                        <li className={`paginate_button page-item previous${metaData.currentPage === 1 ? ' disabled' : null}`}>
                            <a onClick={() => handlePage(metaData.currentPage - 1)} className="page-link">Précédente</a>
                        </li>

                        {metaData.nbMaxPage <= 10 && Array.apply(0, Array(metaData.nbMaxPage)).map(function (x, i) {
                            const p = i + 1
                            return <li key={p}
                                       className={`paginate_button page-item${metaData.currentPage === p ? ' active' : null}`}>
                                <a onClick={() => handlePage(p)} className="page-link">{p}</a>
                            </li>;
                        })}

                        {metaData.nbMaxPage > 10 && <>
                            <li className={`paginate_button page-item${metaData.currentPage === 1 ? ' active' : null}`}>
                                <a onClick={() => handlePage(1)} className="page-link">1</a>
                            </li>
                            <li className={`paginate_button page-item${metaData.currentPage === 2 ? ' active' : null}`}>
                                <a onClick={() => handlePage(2)} className="page-link">2</a>
                            </li>
                            <li className={`paginate_button page-item${metaData.currentPage === 3 ? ' active' : null}`}>
                                <a onClick={() => handlePage(3)} className="page-link">3</a>
                            </li>

                            {(metaData.currentPage < 3 || metaData.currentPage > (metaData.nbMaxPage - 2)) &&
                                <li className="paginate_button disabled">
                                    <a className="page-link">...</a>
                                </li>}

                            {(metaData.currentPage >= 3 && metaData.currentPage <= (metaData.nbMaxPage - 2)) && <>
                                {(metaData.currentPage - 1) > 3 && <>
                                    {(metaData.currentPage - 1) > 4 &&
                                        <li className="paginate_button disabled">
                                            <a className="page-link">...</a>
                                        </li>}

                                    <li className={`paginate_button page-item`}>
                                        <a onClick={() => handlePage(metaData.currentPage - 1)}
                                           className="page-link">{metaData.currentPage - 1}</a>
                                    </li>
                                </>}

                                {(metaData.currentPage) != (metaData.nbMaxPage - 2) && metaData.currentPage != 3 &&
                                    <li className={`paginate_button page-item active`}>
                                        <a className="page-link">{metaData.currentPage}</a>
                                    </li>}

                                {(metaData.currentPage + 1) < (metaData.nbMaxPage - 2) && <>

                                    <li className={`paginate_button page-item`}>
                                        <a onClick={() => handlePage(metaData.currentPage + 1)}
                                           className="page-link">{metaData.currentPage + 1}</a>
                                    </li>

                                    {(metaData.currentPage + 1) < (metaData.nbMaxPage - 3) &&
                                        <li className="paginate_button disabled">
                                            <a className="page-link">...</a>
                                        </li>}
                                </>}
                            </>}

                            <li className={`paginate_button page-item${metaData.currentPage === (metaData.nbMaxPage - 2) ? ' active' : null}`}>
                                <a onClick={() => handlePage((metaData.nbMaxPage - 2))}
                                   className="page-link">{metaData.nbMaxPage - 2}</a>
                            </li>
                            <li className={`paginate_button page-item${metaData.currentPage === (metaData.nbMaxPage - 1) ? ' active' : null}`}>
                                <a onClick={() => handlePage((metaData.nbMaxPage - 1))}
                                   className="page-link">{metaData.nbMaxPage - 1}</a>
                            </li>
                            <li className={`paginate_button page-item${metaData.currentPage === metaData.nbMaxPage ? ' active' : null}`}>
                                <a onClick={() => handlePage(metaData.nbMaxPage)}
                                   className="page-link">{metaData.nbMaxPage}</a>
                            </li>

                        </>}

                        <li className={`paginate_button page-item next${metaData.currentPage === metaData.nbMaxPage ? ' disabled' : null}`}>
                            <a onClick={() => handlePage(metaData.currentPage + 1)}
                               className="page-link">Suivante</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ListBottomPager