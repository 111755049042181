import {useNavigate, useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {useRejection} from "../../context/RejectionContext";


const ImportRejection = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {setSuccessMessage} = useOutletContext()
    const {loading, apiErrors, importFile, load, resetApiErrors} = useRejection()

    const {
        register,
        handleSubmit,
        formState: {errors: formErrors},
        setError,
    } = useForm({})

    useEffect(() => {
        setSuccessMessage(null)
        resetApiErrors()
    }, []);

    useEffect(() => {
        if (apiErrors !== null && Object.keys(apiErrors).length > 0) {
            Object.keys(apiErrors).map((key) => {
                setError(key, apiErrors[key])
            })
        }
    }, [apiErrors]);

    const onSubmit = async (inputs) => {
        load()
        await importFile(inputs.file[0])
    }

    const handleClose = () => {
        if (!loading) {
            resetApiErrors()
            navigate('/rejection')
        }
    }
    return (
        <Modal
            show={true}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('rejection.import.title')}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Form.Group className="mb-3 col-md-6" controlId="rejection.file">
                        <Form.Label>{t('rejection.form.file.label')}</Form.Label>
                        <Form.Control
                            type="file"
                            placeholder={t('rejection.form.file.place-holder')}
                            size="lg"
                            {...register("file", {
                                required: 'error-empty',
                            })}
                            disabled={loading}
                            isInvalid={formErrors && formErrors.file}
                        />
                        {formErrors && formErrors.file && (
                            <Form.Control.Feedback type="invalid">
                                {t(`rejection.form.file.errors.${formErrors.file.message}`)}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                            disabled={loading}
                        >
                            {t('rejection.import.button.cancel')}
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={loading}
                        >
                            {loading && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                style={{marginRight: '10px'}}
                            />}
                            {t('rejection.import.button.save')}
                        </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default ImportRejection