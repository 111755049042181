import useToken from "../useToken";
import {useCallback, useReducer} from "react";
import {client} from "../../api/client";
import Step6ConfirmationAction from "../../pages/TiersPayant/steps/Step6ConfirmationAction";
import step3Validation from "../../pages/TiersPayant/steps/Step3Validation";
import step7CancellationCare from "../../pages/TiersPayant/steps/Step7CancellationCare";

const apiURL = process.env.REACT_APP_API_URL

function reducer(state, action) {
    switch (action.type) {
        case 'FETCH_TIERS_PAYANT':
            return { ...state, loading: true }
        case 'RESET_ERRORS':
            return { ...state, loading: false, apiErrors: null }
        case 'ADD_ERRORS':
            return { ...state, apiErrors: action.payload, loading: false }
        case 'SET_TIERS_PAYANTS':
            return { ...state, tiersPayants: action.payload.tiersPayants, metaData: action.payload.metaData, importStats: null, loading: false, apiErrors: null }
        case 'UPDATE_TIERS_PAYANTS':
            return { ...state, apiErrors: null, loading: false, importStats: null, tiersPayants: state.tiersPayants.map(tiersPayant => {
                    if (tiersPayant.id === parseInt(action.payload.id)) {
                        return action.payload
                    }

                    return tiersPayant
                })}
        case 'SET_IMPORT_STATS':
            return { ...state, importStats: action.payload, loading: false, apiErrors: null }

        default:
            throw new Error('Action inconnue ' + action.type)
    }
}

export function useTiersPayants() {
    const {token} = useToken()
    const [state, dispatch] = useReducer(reducer, {
        loading: true,
        tiersPayants: null,
        countByStatus: null,
        metaData: null,
        importStats: null,
        apiErrors: null
    })

    const fetchAll = useCallback(async (params) => {
        dispatch({ type: 'FETCH_TIERS_PAYANT' })
        client('tiers-payants', {token, params})
            .then(result => {
                dispatch({type: 'SET_TIERS_PAYANTS', payload: result})
            })
    })

    const add = useCallback(async (inputs) => {
        dispatch({ type: 'FETCH_TIERS_PAYANT' })
        client(`tiers-payant`, {body: inputs, token})
            .then(result => {
                fetchAll()
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const update = useCallback(async (id, inputs) => {
        dispatch({ type: 'FETCH_TIERS_PAYANT' })

        client(`tiers-payant/${id}`, {body: inputs, token, method: 'PUT'})
            .then(result => {
                dispatch({type: 'UPDATE_TIERS_PAYANTS', payload: result.tiersPayant})
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const addPatientFile = useCallback(async (id, inputs) => {
        const formData = new FormData();
        formData.append('type', inputs.type)
        formData.append('name', inputs.name[0])

        dispatch({ type: 'FETCH_TIERS_PAYANT' })
        client(`patient-file/${id}/document`, {formData: formData, method: 'POST', token})
            .then(result => {
                fetchAll()
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })

    })

    const resetWorkflow = useCallback(async (id) => {
        dispatch({ type: 'FETCH_TIERS_PAYANT' })

        client(`tiers-payant/${id}/reset-workflow`, {body: {}, token, method: 'POST'})
            .then(result => {
                dispatch({type: 'UPDATE_TIERS_PAYANTS', payload: result.tiersPayant})
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const step1Document = useCallback(async (id, inputs) => {
        dispatch({ type: 'FETCH_TIERS_PAYANT' })

        const formData = new FormData();
        formData.append('type', inputs.type)

        if (inputs.invoiceFile) {
            formData.append('invoiceFile', inputs.invoiceFile[0])
        }
        if (inputs.insuranceCardFile) {
            formData.append('insuranceCardFile', inputs.insuranceCardFile[0])
        }
        if (inputs.medicalCareFile) {
            formData.append('medicalCareFile', inputs.medicalCareFile[0])
        }
        if (inputs.medicalCareFileId) {
            formData.append('medicalCareFileId', inputs.medicalCareFileId)
        }
        if (inputs.type === 'care') {
            const hasInsuranceCardFileBool = inputs.hasInsuranceCardFile === null ? null : ((inputs.hasInsuranceCardFile === 'true' || inputs.hasInsuranceCardFile === true));
            formData.append('hasInsuranceCardFile', !hasInsuranceCardFileBool)
        }
        if (inputs.type === 'prosthesis_orthodontics') {
            const hasMedicalCareFileBool = inputs.hasMedicalCareFile === null ? null : ((inputs.hasMedicalCareFile === 'true' || inputs.hasMedicalCareFile === true));
            formData.append('hasMedicalCareFile', !hasMedicalCareFileBool)
        }

        const isRemainingAmountIsValidBool = inputs.isRemainingAmountIsValid === null ? null : ((inputs.isRemainingAmountIsValid === 'true' || inputs.isRemainingAmountIsValid === true));
        if (isRemainingAmountIsValidBool !== null) {
            formData.append('isRemainingAmountIsValid', isRemainingAmountIsValidBool)
        }

        client(`tiers-payants/${id}/step1`, {formData: formData, token, method: 'POST'})
            .then(result => {
                dispatch({type: 'UPDATE_TIERS_PAYANTS', payload: result.tiersPayant})
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const step2Verification = useCallback(async (id, inputs) => {
        dispatch({ type: 'FETCH_TIERS_PAYANT' })
        client(`tiers-payants/${id}/step2`, {body: inputs, token})
            .then(result => {
                dispatch({type: 'UPDATE_TIERS_PAYANTS', payload: result.tiersPayant})
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const step3Validation = useCallback(async (id, inputs) => {
        dispatch({ type: 'FETCH_TIERS_PAYANT' })
        client(`tiers-payants/${id}/step3`, {body: inputs, token})
            .then(result => {
                dispatch({type: 'UPDATE_TIERS_PAYANTS', payload: result.tiersPayant})
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const step4AdditionalDocument = useCallback(async (id, inputs) => {
        dispatch({ type: 'FETCH_TIERS_PAYANT' })

        const formData = new FormData();
        formData.append('type', inputs.type)

        if (inputs.mandatoryReimbursementFile) {
            formData.append('mandatoryReimbursementFile', inputs.mandatoryReimbursementFile[0])
        }

        const hasMandatoryReimbursementFileBool = inputs.hasMandatoryReimbursementFile === null ? null : ((inputs.hasMandatoryReimbursementFile === 'true' || inputs.hasMandatoryReimbursementFile === true));
        formData.append('hasMandatoryReimbursementFile', !hasMandatoryReimbursementFileBool)

        client(`tiers-payants/${id}/step4`, {formData: formData, token, method: 'POST'})
            .then(result => {
                dispatch({type: 'UPDATE_TIERS_PAYANTS', payload: result.tiersPayant})
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const step5Cancellation = useCallback(async (id, inputs) => {
        dispatch({ type: 'FETCH_TIERS_PAYANT' })
        client(`tiers-payants/${id}/step5`, {body: inputs, token})
            .then(result => {
                dispatch({type: 'UPDATE_TIERS_PAYANTS', payload: result.tiersPayant})
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const step6ConfirmationAction = useCallback(async (id, inputs) => {
        dispatch({ type: 'FETCH_TIERS_PAYANT' })
        client(`tiers-payants/${id}/step6`, {body: inputs, token})
            .then(result => {
                dispatch({type: 'UPDATE_TIERS_PAYANTS', payload: result.tiersPayant})
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const step7CancellationCare = useCallback(async (id, inputs) => {
        dispatch({ type: 'FETCH_TIERS_PAYANT' })
        client(`tiers-payants/${id}/step7`, {body: inputs, token})
            .then(result => {
                dispatch({type: 'UPDATE_TIERS_PAYANTS', payload: result.tiersPayant})
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const restartRefund = useCallback(async (id, inputs) => {


        const formData = new FormData();
        formData.append('isPaid', inputs.isPaid)
        if (inputs.hasCompliantFiles) {
            formData.append('hasCompliantFiles', inputs.hasCompliantFiles)
        }
        if (inputs.sendingType) {
            formData.append('sendingType', inputs.sendingType)
        }
        if (inputs.restartSendTo) {
            formData.append('restartSendTo', inputs.restartSendTo)
        }
        if (inputs.howToManage) {
            formData.append('howToManage', inputs.howToManage)
        }
        if (inputs.paymentDate) {
            formData.append('paymentDate', inputs.paymentDate)
        }
        if (inputs.amountPaid) {
            formData.append('amountPaid', inputs.amountPaid)
        }
        if (inputs.proofOfPaymentFile) {
            formData.append('proofOfPaymentFile', inputs.proofOfPaymentFile[0])
        }

        dispatch({ type: 'FETCH_TIERS_PAYANT' })
        client(`tiers-payants/${id}/restart-refund`, {formData: formData, token, method: 'POST'})
            .then(result => {
                dispatch({type: 'UPDATE_TIERS_PAYANTS', payload: result.tiersPayant})
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const importFile = useCallback(async (inputs) => {
        dispatch({ type: 'FETCH_TIERS_PAYANT' })

        const formData = new FormData();
        formData.append('file', inputs)

        client(`tiers-payants/import`, {formData: formData, token, method: 'POST'})
            .then(result => {
                dispatch({type: 'SET_IMPORT_STATS', payload: result.results})
                fetchAll()
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const document = useCallback(async (id, inputs) => {
        dispatch({ type: 'FETCH_TIERS_PAYANT' })

        const formData = new FormData();

        if (inputs.invoiceFile) {
            formData.append('invoiceFile', inputs.invoiceFile[0])
        }
        if (inputs.insuranceCardFile) {
            formData.append('insuranceCardFile', inputs.insuranceCardFile[0])
        }
        if (inputs.medicalCareFile) {
            formData.append('medicalCareFile', inputs.medicalCareFile[0])
        }
        if (inputs.mandatoryReimbursementFile) {
            formData.append('mandatoryReimbursementFile', inputs.mandatoryReimbursementFile[0])
        }
        if (inputs.proofOfPaymentFile) {
            formData.append('proofOfPaymentFile', inputs.proofOfPaymentFile[0])
        }

        if (inputs.otherFile) {
            formData.append('otherFile', inputs.otherFile[0])
        }

        client(`tiers-payants/${id}/document`, {formData: formData, token, method: 'POST'})
            .then(result => {
                dispatch({type: 'UPDATE_TIERS_PAYANTS', payload: result.tiersPayant})
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const deleteDocument = useCallback(async (id, tiersPayantFileId) => {
        dispatch({ type: 'FETCH_TIERS_PAYANT' })

        client(`tiers-payants/${id}/document`, {body: {tiersPayantFileId}, token, method: 'Delete'})
            .then(result => {
                dispatch({type: 'UPDATE_TIERS_PAYANTS', payload: result.tiersPayant})
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const createComment = useCallback(async (id, inputs) => {
        dispatch({ type: 'FETCH_TIERS_PAYANT' })
        client(`tiers-payants/${id}/comment`, {body: inputs, token})
            .then(result => {
                dispatch({type: 'UPDATE_TIERS_PAYANTS', payload: result.tiersPayant})
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const resetApiErrors = () => {
        dispatch({ type: 'RESET_ERRORS' })
    }

    const load = useCallback(async() => {
        dispatch({ type: 'FETCH_TIERS_PAYANT' })
    })

    return [state, { fetchAll, add, update, addPatientFile, resetWorkflow, importFile, document, deleteDocument, createComment, step1Document, step2Verification, step3Validation, step4AdditionalDocument, step5Cancellation, step6ConfirmationAction, step7CancellationCare, restartRefund, resetApiErrors, load }]
}