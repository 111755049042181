import {useContext, createContext, useState, useEffect} from "react";
import useToken from "../hooks/useToken";
import { useNavigate } from "react-router-dom";
import {client} from "../api/client";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const {token, setToken, removeToken} = useToken()
    const [user, setUser] = useState(null);
    const [errors, setErrors] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (token && !user) {
            client('user/me', {token})
                .then(result => {
                    setUser(result.user)
                    setIsLoading(false)
                })
                .catch(result => {
                    setErrors(result.errors)
                    setUser(null);
                    removeToken();
                    setIsLoading(false)
                    navigate('/login')
                })
        }
        if (!user && !token) {
            const pathname = window.location.pathname;
            const publicPathnames = ["login", "lost-password", "validate-invitation"]
            setUser(null);
            removeToken();
            setIsLoading(false)
            if (undefined !== publicPathnames.find(publicPathname => pathname.includes('publicPathname'))) {
                navigate('/login')
            }
        }
    }, []);

    const hasRight = (routeName) => {
        if (!user || Object.keys(user.rights).length === 0) {
            return false;
        }

        switch (routeName) {
            case 'home':
                return true
            case 'profile':
                return true;
            case 'health_insurance':
                return user.rights.includes('read_health_insurances')
            case 'health_insurance_update':
                return user.rights.includes('update_health_insurance')
            case 'health_insurance_delete':
                return user.rights.includes('delete_health_insurance')
            case 'health_insurance_new':
                return user.rights.includes('create_health_insurance')
            case 'customer':
                return user.rights.includes('read_customers')
            case 'customer_update':
                return user.rights.includes('update_customer')
            case 'customer_delete':
                return user.rights.includes('delete_customer')
            case 'customer_new':
                return user.rights.includes('create_customer')
            case 'user':
                return user.rights.includes('read_users')
            case 'user_update':
                return user.rights.includes('update_user')
            case 'user_delete':
                return user.rights.includes('delete_user')
            case 'user_new':
                return user.rights.includes('create_user')
            case 'tiers_payant':
                return user.rights.includes('read_tiers_payants')
            case 'tiers_payant_new':
                return user.rights.includes('create_tiers_payant')
            case 'tiers_payant_import':
                return user.rights.includes('import_tiers_payant')
            case 'tiers_payant_manage':
                return user.rights.includes('manage_tiers_payant')
            case 'tiers_payant_update':
                return user.rights.includes('update_tiers_payant')
            case 'tiers_payant_restart_refund':
                return user.rights.includes('restart_refund_tiers_payant')
            case 'tiers_payant_reset':
                return user.rights.includes('reset_tiers_payant')
            case 'tiers_payant_document':
                return user.rights.includes('document_tiers_payant')
            case 'tiers_payant_patient_files':
                return user.rights.includes('document_tiers_payant')
            case 'tiers_payant_comment':
                return user.rights.includes('read_comments_tiers_payant')
            case 'rejection':
                return user.rights.includes('read_rejections')
            case 'rejection_new':
                return user.rights.includes('create_rejection')
            case 'rejection_import':
                return user.rights.includes('import_rejection')
        }

        return false
    }

    const refreshUser = async () => {
        if (token) {
            client('user/me', {token})
                .then(result => {
                    setUser(result.user)
                })
                .catch(result => {
                    setErrors(result.errors)
                    setUser(null);
                    removeToken();
                    navigate('/login')
                })
        } else {
            setUser(null);
            removeToken();
            navigate('/login')
        }
    }

    const loginAction = async ({email, password}) => {
        setIsLoading(true)
        client('login_check', {body: {username: email, password}})
            .then(result => {
                setToken(result.token)
                client('user/me', {token: result.token})
                    .then(result => {
                        setUser(result.user)
                        setIsLoading(false)
                        navigate('/')
                    })
                    .catch(result => {
                        setErrors(result.errors)
                        setUser(null);
                        removeToken();
                        setIsLoading(false)
                    })
            })
            .catch(result => {
                setErrors(result.errors)
                setUser(null);
                removeToken();
                setIsLoading(false)
            })
    }

    const logOut = () => {
        setUser(null);
        removeToken();
        navigate("/login");
    }

    return <AuthContext.Provider value={{ token, user, loginAction, logOut, errors, isLoading, refreshUser, hasRight }}>
        {children}
    </AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};