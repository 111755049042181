import {Button, Form, Modal, Row} from "react-bootstrap";
import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useTiersPayant} from "../../context/TiersPayantContext";


const UpdateTiersPayant = ({handleClose, tiersPayant}) => {
    const { t } = useTranslation();
    const {loading, apiErrors, update, load, resetApiErrors} = useTiersPayant()
    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
        control,
        setValue,
        setError,
        clearErrors
    } = useForm({})

    useEffect(() => {
        if (tiersPayant) {
            setValue("patientName", tiersPayant.patientName)
            setValue("nirCode", tiersPayant.nirCode)
            setValue("fseCode", tiersPayant.fseCode)
            setValue("remainingAmount", tiersPayant.remainingAmount +'')
        }
    }, [tiersPayant]);

    const onSubmit = (inputs) => {
        update(tiersPayant.id, inputs)
    }

    return (<div>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Form.Group className="mb-3 col-md-6" controlId="tiers-payant.patientName">
                    <Form.Label>{t('tiers-payant.form.patientName.label')}</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('tiers-payant.form.patientName.place-holder')}
                        size="lg"
                        {...register("patientName", {
                            required: 'error-empty',
                        })}
                        disabled={loading}
                        isInvalid={formErrors && formErrors.patientName}
                    />
                    {formErrors && formErrors.patientName && (
                        <Form.Control.Feedback type="invalid">
                            {t(`tiers-payant.form.patientName.errors.${formErrors.patientName.message}`)}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
                <Form.Group className="mb-3 col-md-6" controlId="tiers-payant.nirCode">
                    <Form.Label>{t('tiers-payant.form.nirCode.label')}</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('tiers-payant.form.nirCode.place-holder')}
                        size="lg"
                        {...register("nirCode", {
                            required: 'error-empty',
                        })}
                        disabled={loading}
                        isInvalid={formErrors && formErrors.nirCode}
                    />
                    {formErrors && formErrors.nirCode && (
                        <Form.Control.Feedback type="invalid">
                            {t(`tiers-payant.form.nirCode.errors.${formErrors.nirCode.message}`)}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
            </Row>
            <Row>
                <Form.Group className="mb-3 col-md-6" controlId="tiers-payant.fseCode">
                    <Form.Label>{t('tiers-payant.form.fseCode.label')}</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('tiers-payant.form.fseCode.place-holder')}
                        size="lg"
                        {...register("fseCode", {
                            required: 'error-empty',
                        })}
                        disabled={loading}
                        isInvalid={formErrors && formErrors.fseCode}
                    />
                    {formErrors && formErrors.fseCode && (
                        <Form.Control.Feedback type="invalid">
                            {t(`tiers-payant.form.fseCode.errors.${formErrors.fseCode.message}`)}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
                <Form.Group className="mb-3 col-md-6" controlId="tiers-payant.remainingAmount">
                    <Form.Label>{t('tiers-payant.form.remainingAmount.label')}</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('tiers-payant.form.remainingAmount.place-holder')}
                        size="lg"
                        {...register("remainingAmount", {
                            required: 'error-empty',
                        })}
                        disabled={loading}
                        isInvalid={formErrors && formErrors.remainingAmount}
                    />
                    {formErrors && formErrors.remainingAmount && (
                        <Form.Control.Feedback type="invalid">
                            {t(`tiers-payant.form.remainingAmount.errors.${formErrors.remainingAmount.message}`)}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
            </Row>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    {t('tiers-payant.update.button.cancel')}
                </Button>
                <Button variant="primary" type="submit">
                    {t('tiers-payant.update.button.save')}
                </Button>
            </Modal.Footer>
        </Form>
    </div>)
}

export default UpdateTiersPayant