import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useCustomers} from "../../hooks/api/customer";
import {Controller, useForm} from "react-hook-form";
import {Button, Form, Modal, Row} from "react-bootstrap";
import {useRejection} from "../../context/RejectionContext";
import Select from "react-select";
import DatePicker from "react-date-picker";
import {usePayingAgency} from "../../hooks/api/payingAgency";


const CreateRejection = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [canClose, setCanClose] = useState(false)
    const [formErrors, setFormErrors] = useState(false)
    const [customersOptions, setCustomersOptions] = useState([])
    const [payingAgenciesOptions, setPayingAgenciesOptions] = useState([])
    const {setSuccessMessage} = useOutletContext()
    const {loading, apiErrors, add, load, resetApiErrors} = useRejection()
    const [{customers}, {fetchAll: fetchAllCustomer}] = useCustomers()
    const [{payingAgencies}, {fetchAll: fetchAllPayingAgencies}] = usePayingAgency()

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({})

    useEffect(() => {
        if (customers) {
            const options = customers.map((customer => {
                return { label: customer.name, value: customer.id }
            }))
            setCustomersOptions(options)
        }

    }, [customers])
console.log(payingAgencies)
    useEffect(() => {
        if (payingAgencies) {
            const options = payingAgencies.map((payingAgency => {
                return { label: `${payingAgency.name} (${payingAgency.code})`, value: payingAgency.id }
            }))
            setPayingAgenciesOptions(options)
        }

    }, [payingAgencies])

    useEffect(() => {
        setSuccessMessage(null)
        fetchAllCustomer()
        fetchAllPayingAgencies()
    }, []);

    useEffect(() => {
        setFormErrors({...errors, ...apiErrors})
    }, [errors])

    useEffect(() => {
        setFormErrors({...apiErrors, ...errors})
    }, [apiErrors]);

    useEffect(() => {
        if (canClose && !loading && !apiErrors) {
            setSuccessMessage(t('rejection.add.success_message'))
            handleClose()
        }

    }, [canClose, loading, apiErrors]);

    const onSubmit = async (inputs) => {
        load()
        await add(inputs)
        setCanClose(true)
    }

    const handleClose = () => {
        resetApiErrors()
        navigate('/rejection')
    }

    return (
        <Modal
            show={true}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('rejection.add.title')}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Row>
                        <Form.Group className="mb-3 col-md-6" controlId="customer.customerId">
                            <Form.Label>{t('rejection.form.customer.label')}</Form.Label>
                            <Controller
                                name="customerId"
                                control={control}
                                render={({ field, value, name, ref }) => (
                                    <Select
                                        name={field.name}
                                        placeholder={t('rejection.form.customer.place-holder')}
                                        inputRef={field.ref}
                                        options={customersOptions}
                                        value={customersOptions.find((c) => c.value === field.value)}
                                        onChange={val => field.onChange(val.value)}
                                        isInvalid={formErrors && formErrors.customerId}
                                        className={`p-0 form-control form-control-lg ${formErrors && formErrors.customerId ? ' is-invalid' : ''}`}
                                    />)
                                }
                                size="lg"
                                {...register("customerId", {
                                    required: 'error-empty',
                                })}

                            />
                            {formErrors && formErrors.customerId && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`rejection.form.customer.errors.${formErrors.customerId.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3 col-md-6" controlId="rejection.payingAgencyId">
                            <Form.Label>{t('rejection.form.payingAgency.label')}</Form.Label>
                            <Controller
                                name="payingAgencyId"
                                control={control}
                                render={({ field, value, name, ref }) => (
                                    <Select
                                        name={field.name}
                                        placeholder={t('rejection.form.payingAgency.place-holder')}
                                        inputRef={field.ref}
                                        options={payingAgenciesOptions}
                                        value={payingAgenciesOptions.find((c) => c.value === field.value)}
                                        onChange={val => field.onChange(val.value)}
                                        isInvalid={formErrors && formErrors.payingAgencyId}
                                        className={`p-0 form-control form-control-lg ${formErrors && formErrors.payingAgencyId ? ' is-invalid' : ''}`}
                                    />)
                                }
                                size="lg"
                                {...register("payingAgencyId", {
                                    required: 'error-empty',
                                })}

                            />
                            {formErrors && formErrors.payingAgencyId && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`rejection.form.payingAgency.errors.${formErrors.payingAgencyId.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>

                    </Row>
                    <Row>
                        <Form.Group className="mb-3 col-md-6" controlId="rejection.patientName">
                            <Form.Label>{t('rejection.form.patientName.label')}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('rejection.form.patientName.place-holder')}
                                size="lg"
                                {...register("patientName", {
                                    required: 'error-empty',
                                })}
                                disabled={loading}
                                isInvalid={formErrors && formErrors.patientName}
                            />
                            {formErrors && formErrors.patientName && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`rejection.form.patientName.errors.${formErrors.patientName.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3 col-md-6" controlId="rejection.nirCode">
                            <Form.Label>{t('rejection.form.nirCode.label')}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('rejection.form.nirCode.place-holder')}
                                size="lg"
                                {...register("nirCode", {
                                    required: 'error-empty',
                                })}
                                disabled={loading}
                                isInvalid={formErrors && formErrors.nirCode}
                            />
                            {formErrors && formErrors.nirCode && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`rejection.form.nirCode.errors.${formErrors.nirCode.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3 col-md-6" controlId="rejection.invoiceDate">
                            <Form.Label>{t('rejection.form.invoiceDate.label')}</Form.Label>
                            <Controller
                                name="text"
                                control={control}
                                render={({ field, value, name, ref }) => (
                                    <DatePicker
                                        onChange={field.onChange}
                                        value={field.value}
                                        className={`p-0 form-control form-control-lg ${formErrors && formErrors.invoiceDate ? ' is-invalid' : ''}`}
                                        format={"dd-MM-y"}
                                    />
                                )
                                }
                                size="lg"
                                {...register("invoiceDate", {
                                    required: 'error-empty',
                                })}
                            />
                            {formErrors && formErrors.invoiceDate && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`rejection.form.invoiceDate.errors.${formErrors.invoiceDate.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3 col-md-6" controlId="rejection.invoiceNumber">
                            <Form.Label>{t('rejection.form.invoiceNumber.label')}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('rejection.form.invoiceNumber.place-holder')}
                                size="lg"
                                {...register("invoiceNumber", {
                                    required: 'error-empty',
                                })}
                                disabled={loading}
                                isInvalid={formErrors && formErrors.invoiceNumber}
                            />
                            {formErrors && formErrors.invoiceNumber && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`rejection.form.invoiceNumber.errors.${formErrors.invoiceNumber.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3 col-md-6" controlId="rejection.fseCode">
                            <Form.Label>{t('rejection.form.fseCode.label')}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('rejection.form.fseCode.place-holder')}
                                size="lg"
                                {...register("fseCode", {
                                    required: 'error-empty',
                                })}
                                disabled={loading}
                                isInvalid={formErrors && formErrors.fseCode}
                            />
                            {formErrors && formErrors.fseCode && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`rejection.form.fseCode.errors.${formErrors.fseCode.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3 col-md-6" controlId="rejection.remainingAmount">
                            <Form.Label>{t('rejection.form.remainingAmount.label')}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('rejection.form.remainingAmount.place-holder')}
                                size="lg"
                                {...register("remainingAmount", {
                                    required: 'error-empty',
                                })}
                                disabled={loading}
                                isInvalid={formErrors && formErrors.remainingAmount}
                            />
                            {formErrors && formErrors.remainingAmount && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`rejection.form.remainingAmount.errors.${formErrors.remainingAmount.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3 col-md-6" controlId="rejection.motif">
                            <Form.Label>{t('rejection.form.motif.label')}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('rejection.form.motif.place-holder')}
                                size="lg"
                                {...register("motif", {
                                    required: 'error-empty',
                                })}
                                disabled={loading}
                                isInvalid={formErrors && formErrors.motif}
                            />
                            {formErrors && formErrors.motif && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`rejection.form.motif.errors.${formErrors.motif.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        {t('rejection.add.button.cancel')}
                    </Button>
                    <Button variant="primary" type="submit">
                        {t('rejection.add.button.save')}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>

    )
}

export default CreateRejection