import {Button, Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {useTiersPayant} from "../../context/TiersPayantContext";


const CommentTiersPayant = ({tiersPayant, handleClose}) => {
    const { t } = useTranslation();
    const {loading, apiErrors, createComment, resetApiErrors} = useTiersPayant()
    const [comments, setComments] = useState([])

    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
        control,
        setValue,
        setError,
        clearErrors
    } = useForm({})

    const onSubmit = (inputs) => {
        createComment(tiersPayant.id, inputs)
        setValue('comment', '')
    }

    useEffect(() => {
        if (tiersPayant) {
            if (Object.keys(tiersPayant.comments).length > 0) {
                tiersPayant.comments.sort((a, b) => b.id - a.id)
                setComments(tiersPayant.comments)
            }
        }
    }, [tiersPayant]);

    return (<div>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <ul className="timeline mt-2 mb-0">
                {comments && comments.map(comment => {
                    return (
                        <li className="timeline-item" key={comment.id}>
                            <strong>{comment.sentBy.firstName} {comment.sentBy.lastName}</strong> {comment.isAutomatic && <small>(Auto)</small>}
                            <span className="float-end text-muted text-sm">
                                {comment.createdAt}
                            </span>
                            <p className={comment.isAutomatic ? 'fst-italic' : ''}>{comment.comment}</p>
                        </li>
                    )
                })}
            </ul>
            <Form.Group className="mb-3" controlId="tiers-payant.comment">
                <Form.Label><strong>{t('tiers-payant.manage.form.comment.label')}</strong></Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    type="textarea"
                    size="lg"
                    {...register("comment", {
                        required: 'error-empty',
                    })}
                    isInvalid={formErrors && formErrors.comment}
                />
                {formErrors && formErrors.comment && (
                    <Form.Control.Feedback type="invalid">
                        {t(`tiers-payant.manage.form.comment.errors.${formErrors.comment.message}`)}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Button variant="primary" type="submit">
                {t('tiers-payant.manage.form.comment.button.send')}
            </Button>
        </Form>
    </div>)
}

export default CommentTiersPayant