import React, {useState} from "react";


const Stepper = ({stepsConfig, currentStep, onClickStep}) => {
    const [isComplete, setIsComplete] = useState(false);
    const [margins, setMargins] = useState({
        marginLeft: 0,
        marginRight: 0,
    });
    const calculateProgressBarWidth = () => {
        return ((currentStep) / (stepsConfig.length - 1)) * 100;
    };

    return (
        <div className="stepper">
            {stepsConfig.map((step, index) => {
                const stepNumber = index + 1
                const isClickable = stepsConfig[index].isClickable
                return (
                    <div
                        key={step.name}
                        //ref={(el) => (stepRef.current[index] = el)}
                        className={`step ${currentStep >
                        index || isComplete ?
                            "complete" : ""
                        } ${currentStep === index ? "active" : ""} ${isClickable ? "clickable" : ""}`}
                        onClick={() => {
                                if (isClickable) {
                                    onClickStep(index)
                                }
                            }
                        }
                    >
                        <div className="step-number">
                            {currentStep > index || isComplete ? (
                                <span>&#10003;</span>
                            ) : (
                                stepNumber
                            )}
                        </div>
                        <div className="step-name">{step.name}</div>
                    </div>
                );
            })}

            <div
                className="progress-bar"
                style={{
                    width: `calc(100% - ${margins.marginLeft
                    + margins.marginRight}px)`,
                    marginLeft: margins.marginLeft,
                    marginRight: margins.marginRight,
                }}
            >
                <div
                    className="progress"
                    style={{width: `${calculateProgressBarWidth()}%`}}
                ></div>
            </div>
        </div>
    )
}

export default Stepper