import {useCallback, useReducer} from "react";
import {client} from "../../api/client";
import useToken from "../useToken";

function reducer(state, action) {
    switch (action.type) {
        case 'FETCH_PAYING_AGENCY':
            return { ...state, loading: true }
        case 'RESET_ERRORS':
            return { ...state, loading: false, apiErrors: null }
        case 'SET_PAYING_AGENCIES':
            return { ...state, payingAgencies: action.payload, loading: false, apiErrors: null }
        case 'ADD_ERRORS':
            return { ...state, apiErrors: action.payload, loading: false }
        default:
            throw new Error('Action inconnue ' + action.type)
    }
}

export function usePayingAgency() {
    const {token} = useToken()
    const [state, dispatch] = useReducer(reducer, {
        loading: true,
        payingAgencies: null,
        apiErrors: null
    })

    const fetchAll = useCallback(async () => {
        client('paying-agencies', {token})
            .then(result => {
                dispatch({type: 'SET_PAYING_AGENCIES', payload: result.payingAgencies})
            })
    })

    const resetApiErrors = () => {
        dispatch({ type: 'RESET_ERRORS' })
    }

    const load = useCallback(async() => {
        dispatch({ type: 'FETCH_PAYING_AGENCY' })
    })

    return [state, { fetchAll, resetApiErrors, load }]
}