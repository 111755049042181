import {createContext, useContext} from "react";
import {useRejections} from "../hooks/api/rejection";

const RejectionContext = createContext();

const RejectionProvider = ({children}) => {
    const [{rejections, importStats, metaData, apiErrors, loading}, {fetchAll, add, importFile, resetApiErrors, load}] = useRejections()

    return <RejectionContext.Provider value={{ rejections, importStats, metaData, apiErrors, loading, fetchAll, add, importFile, resetApiErrors, load }}>
        {children}
    </RejectionContext.Provider>;
}

export default RejectionProvider

export const useRejection = () => {
    return useContext(RejectionContext);
};