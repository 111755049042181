import {useCallback, useReducer} from "react";
import {client} from "../../api/client";
import useToken from "../useToken";

function reducer(state, action) {
    switch (action.type) {
        case 'FETCH_REJECTION':
            return { ...state, loading: true }
        case 'RESET_ERRORS':
            return { ...state, loading: false, apiErrors: null }
        case 'SET_REJECTIONS':
            return { ...state, rejections: action.payload.rejections, metaData: action.payload.metaData, importStats: null, loading: false, apiErrors: null }
        case 'ADD_CUSTOMER':
            return { ...state, rejections: [...state.rejections, ...action.payload], apiErrors: null }
        case 'ADD_ERRORS':
            return { ...state, apiErrors: action.payload, loading: false }
        case 'UPDATE_REJECTION':
            return { ...state, apiErrors: null, loading: false, rejections: state.rejections.map(rejection => {
                    if (rejection.id === parseInt(action.payload.id)) {
                        return action.payload
                    }

                    return rejection
                })}
        case 'SET_IMPORT_STATS':
            return { ...state, importStats: action.payload, loading: false, apiErrors: null }
        default:
            throw new Error('Action inconnue ' + action.type)
    }
}

export function useRejections() {
    const {token} = useToken()
    const [state, dispatch] = useReducer(reducer, {
        loading: true,
        rejections: null,
        metaData: null,
        importStats: null,
        apiErrors: null
    })

    const fetchAll = useCallback(async (params) => {
        client('rejections', {token, params})
            .then(result => {
                dispatch({type: 'SET_REJECTIONS', payload: result})
            })
    })

    const add = useCallback(async (inputs) => {
        dispatch({ type: 'FETCH_REJECTION' })
        client(`rejection`, {body: inputs, token})
            .then(result => {
                fetchAll()
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const importFile = useCallback(async (inputs) => {
        dispatch({ type: 'FETCH_REJECTION' })

        const formData = new FormData();
        formData.append('file', inputs)

        client(`rejection/import`, {formData: formData, token, method: 'POST'})
            .then(result => {
                dispatch({type: 'SET_IMPORT_STATS', payload: result.results})
                fetchAll()
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const resetApiErrors = () => {
        dispatch({ type: 'RESET_ERRORS' })
    }

    const load = useCallback(async() => {
        dispatch({ type: 'FETCH_REJECTION' })
    })

    return [state, { fetchAll, add, importFile, resetApiErrors, load }]
}