import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useTiersPayant} from "../../context/TiersPayantContext";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import {Link, Outlet, useSearchParams} from "react-router-dom";
import SuccessAlert from "../../components/Layout/SuccessAlert";
import TiersPayantStatusBadge from "../../components/TiersPayantStatutBadge";
import {Controller, useForm} from "react-hook-form";
import DatePicker from "react-date-picker";
import Select from "react-select";
import {useCustomers} from "../../hooks/api/customer";
import {useAuth} from "../../context/AuthContext";
import ListBottomPager from "../../components/ListBottomPager";

const ListTiersPayant = () => {
    const { t } = useTranslation();
    const [successMessage, setSuccessMessage] = useState(null)
    const [customersOptions, setCustomersOptions] = useState([])
    const [statusOptions, setStatusOptions] = useState([])
    const [listParams, setListParams] = useState(null)
    const {tiersPayants, metaData, fetchAll} = useTiersPayant()
    const [{customers}, {fetchAll: fetchAllCustomer}] = useCustomers()
    const {hasRight} = useAuth()

    const [searchParams, setSearchParams] = useSearchParams();

    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
        control,
        setValue,
        reset
    } = useForm({})

    useEffect(() => {
        fetchAllCustomer()
        const date = searchParams.get('date');
        const customerId = searchParams.get('customerId');
        const status = searchParams.get('status');
        const page = searchParams.get('page');

        if (date) {
            setValue('date', date)
        }
        if (customerId) {
            setValue('customerId', parseInt(customerId))
        }
        if (status) {
            setValue('status', status)
        }
        if (page) {
            setValue('page', page)
        }
    }, []);

    useEffect(() => {
        if (customers) {
            const options = customers.map((customer => {
                return { label: customer.name, value: customer.id }
            }))
            setCustomersOptions(options)
        }
    }, [customers])

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            let color = ''
            if (data.value === 'new') {
                color = '#19E7CA'
            } else if (data.value === 'new_restart') {
                color = '#19E7CA'
            } else if (data.value === 'waiting_mandatory_reimbursement_file') {
                color = '#3367D9'
            } else if (data.value === 'waiting_sending') {
                color = '#32419C'
            } else if (data.value === 'waiting_invoice') {
                color = '#1F9BCF'
            } else if (data.value === 'sent') {
                color = '#0437FF'
            } else if (data.value === 'closed') {
                color = '#1E116F'
            } else if (data.value === 'closed_insured_paid') {
                color = '#1E116F'
            } else if (data.value === 'closed_uncollectible') {
                color = '#1E116F'
            } else if (data.value === 'closed_cancelled') {
                color = '#1E116F'
            } else if (data.value === 'impossible_invoicing_internal') {
                color = '#FF8B13'
            } else if (data.value === 'impossible_invoicing_waiting_customer') {
                color = '#BA6712'
            } else if (data.value === 'canceled_internal') {
                color = '#E50000'
            } else if (data.value === 'canceled_waiting_customer') {
                color = '#B02000'
            } else if (data.value === 'waiting_paiement') {
                color = '#00C3EC'
            } else if (data.value === 'verification_paiement') {
                color = '#00C3EC'
            } else if (data.value === 'restart_refund') {
                color = '#FFB800'
            } else if (data.value === 'restart_refund_impossible_internal') {
                color = '#495057'
            } else if (data.value === 'restart_refund_impossible_ask_document') {
                color = '#495057'
            } else if (data.value === 'restart_refund_impossible_ask_invoice') {
                color = '#495057'
            } else if (data.value === 'refund_restarted') {
                color = '#7505FF'
            } else if (data.value === 'paid') {
                color = '#00752F'
            } else if (data.value === 'partially_paid') {
                color = '#00752F'
            }

            return {
                ...styles,
                backgroundColor: color,
                color: '#FFF'
            }
        },
    };

    useEffect(() => {
        if (metaData) {
            const options = Object.keys(metaData.countByStatus).map(key => {
                return { label: `${t(`tiers-payant.status.${key}`)} - ${metaData.countByStatus[key]}`, value: key, className: `select-option-${key}` }
            })

            setStatusOptions(options)
        }
    }, [metaData])

    const handleSelect = (event) => {
        const {name, value} = event?.target;
        setSearchParams(searchParams => {
            if (value === undefined) {
                searchParams.delete(name)
            } else {
                searchParams.set(name, value);
            }
            searchParams.set('page', '1');

            return searchParams;
        });
    }

    const handleDate = (event) => {
        const {name, value} = event?.target;
        setSearchParams(searchParams => {
            if (value === undefined || value === null) {
                searchParams.delete(name)
            } else {
                const stringDate = value.toLocaleDateString("fr").replaceAll('/', '-')
                searchParams.set(name, stringDate);
            }
            searchParams.set('page', '1');

            return searchParams;
        });
    }

    const handlePage = (page) => {
        setSearchParams(searchParams => {
            if (page === undefined || page === null) {
                searchParams.delete('page')
            } else {
                searchParams.set('page', page);
            }

            return searchParams;
        });
    }

    useEffect(() => {
        const customerId = searchParams.get('customerId');
        const date = searchParams.get('date');
        const status = searchParams.get('status');
        const page = searchParams.get('page');
        const query = searchParams.get('query');

        const params = []
        if (customerId) {
            params['customerId'] = customerId
        } else {
            reset({customerId: null})
        }

        if (date) {
            params['date'] = date
        }
        if (status) {
            params['status'] = status
        }
        if (page) {
            params['page'] = page
        }
        if (query) {
            params['query'] = query
        }

        if (Object.keys(params).length > 0) {
            const listParamsString = Object.keys(params).map((key) => {
                return (`${key}=${params[key]}`)
            }).join('&')

            setListParams(listParamsString)
        } else {
            setListParams(null)
        }

        fetchAll(params)
    }, [searchParams]);

    return (
        <DefaultLayout>
            {hasRight('tiers_payant_import') && <Link
                to={'/tiers-payant/import'}
                className="btn btn-primary float-end mt-n1 ms-2"
            >
                <i className="fa-solid fa-file-excel" style={{marginRight: '10px'}}></i>
                {t('tiers-payant.list.button.import')}
            </Link>}
            {hasRight('tiers_payant_new') && <Link
                to={'/tiers-payant/new'}
                className="btn btn-primary float-end mt-n1"
            >
                <i className="fas fa-plus" style={{marginRight: '10px'}}></i>
                {t('tiers-payant.list.button.add')}
            </Link>}
            <h1 className="h3 mb-3">{t('tiers-payant.list.title')}</h1>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-12 col-md-3">
                                    <Controller
                                        name="customerId"
                                        control={control}
                                        size="lg"
                                        {...register("customerId", {
                                            onChange: (e) => {
                                                handleSelect(e)
                                            }
                                        })}
                                        render={({field, value, name, ref}) => (
                                            <Select
                                                fieldName={field.name}
                                                placeholder={t('tiers-payant.list.filters.customer.place-holder')}
                                                inputRef={field.ref}
                                                options={customersOptions}
                                                value={customersOptions.find((c) => c.value === field.value)}
                                                onChange={val => field.onChange(val?.value)}
                                                isInvalid={formErrors && formErrors.healthInsuranceId}
                                                className={`p-0 form-control form-control-lg ${formErrors && formErrors.customerId ? ' is-invalid' : ''}`}
                                                isClearable
                                            />)
                                        }
                                    />
                                </div>
                                <div className="col-sm-12 col-md-3">
                                    <Controller
                                        name="date"
                                        control={control}
                                        render={({field, value, name, ref}) => (
                                            <DatePicker
                                                onChange={field.onChange}
                                                value={field.value}
                                                className={`p-0 form-control form-control-lg ${formErrors && formErrors.invoiceDate ? ' is-invalid' : ''}`}
                                                format={"dd-MM-y"}
                                            />
                                        )
                                        }
                                        size="lg"
                                        {...register("date", {
                                            onChange: (event) => handleDate(event),
                                        })}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-3">
                                    <Controller
                                        name="status"
                                        control={control}
                                        size="lg"
                                        {...register("status", {
                                            onChange: (e) => {
                                                handleSelect(e)
                                            }
                                        })}
                                        render={({field, value, name, ref}) => (
                                            <Select
                                                fieldName={field.name}
                                                placeholder={t('tiers-payant.list.filters.status.place-holder')}
                                                inputRef={field.ref}
                                                options={statusOptions}
                                                value={statusOptions.find((c) => c.value === field.value)}
                                                onChange={val => field.onChange(val?.value)}
                                                isInvalid={formErrors && formErrors.status}
                                                className={`p-0 form-control form-control-lg ${formErrors && formErrors.status ? ' is-invalid' : ''}`}
                                                styles={colourStyles}
                                                isClearable
                                            />)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                                <div className="row">
                                    <div className="col-sm-12">
                                        {successMessage && <SuccessAlert message={successMessage}/>}

                                        <div className="table-responsive">
                                            <table className="table table-hover table-striped">
                                                <thead>
                                                <tr>
                                                    <th scope="col">{t('tiers-payant.list.customer')}</th>
                                                    <th scope="col">{t('tiers-payant.list.patientName')}</th>
                                                    <th scope="col">{t('tiers-payant.list.invoiceDate')}</th>
                                                    <th scope="col">{t('tiers-payant.list.invoiceNumber')}</th>
                                                    <th scope="col">{t('tiers-payant.list.fseCode')}</th>
                                                    <th scope="col">{t('tiers-payant.list.nirCode')}</th>
                                                    <th scope="col">{t('tiers-payant.list.healthInsurance')}</th>
                                                    <th scope="col">{t('tiers-payant.list.remainingAmount')}</th>
                                                    <th scope="col">{t('tiers-payant.list.status')}</th>
                                                    <th>Actions</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {tiersPayants && tiersPayants.map((tiersPayant, key) => (
                                                    <tr
                                                        key={key}
                                                    >
                                                        <td>{tiersPayant.customer?.name}</td>
                                                        <td>{tiersPayant.patientName}</td>
                                                        <td>{tiersPayant.invoiceDate}</td>
                                                        <td>{tiersPayant.invoiceNumber}</td>
                                                        <td>{tiersPayant.fseCode}</td>
                                                        <td>{tiersPayant.nirCode}</td>
                                                        <td>{tiersPayant.healthInsurance?.name}</td>
                                                        <td>{tiersPayant.remainingAmount}€</td>
                                                        <td>
                                                            <TiersPayantStatusBadge status={tiersPayant.status}/>
                                                        </td>
                                                        <td>
                                                            {hasRight('tiers_payant_comment') && <Link
                                                                to={`/tiers-payant/comment/${tiersPayant.id}${listParams ? `?${listParams}` : ''}`}
                                                            >
                                                                Gérer le dossier
                                                            </Link>}
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <ListBottomPager metaData={metaData} handlePage={handlePage}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Outlet
                    context={{setSuccessMessage}}
                />
            </div>

        </DefaultLayout>
    )
}

export default ListTiersPayant