import {Button, Form, FormCheck, Modal, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import useToken from "../../../hooks/useToken";

const apiURL = process.env.REACT_APP_API_URL

const Step4AdditionalDocument = ({tiersPayant, loading, handleClose, onSubmit}) => {
    const { t } = useTranslation();
    const [showMandatoryReimbursementFile, setShowMandatoryReimbursementFile] = useState(true)
    const [hasMandatoryReimbursementFile, setHasMandatoryReimbursementFile] = useState(true)
    const {token} = useToken()

    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
        control,
        setValue,
    } = useForm({})

    useEffect(() => {

        if (tiersPayant && false === tiersPayant.hasMandatoryReimbursementFile) {
            setValue('hasMandatoryReimbursementFile', true)
        }

        if (tiersPayant && tiersPayant.mandatoryReimbursementFile && true === tiersPayant.hasMandatoryReimbursementFile) {
            setShowMandatoryReimbursementFile(false)
            setHasMandatoryReimbursementFile(true)
        }
        if (tiersPayant && false === tiersPayant.hasMandatoryReimbursementFile) {
            setHasMandatoryReimbursementFile(false)
        }

    }, [tiersPayant]);
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="tiers-payant.mandatory-reimbursement-file">

                <Form.Label>
                    {t('tiers-payant.manage.form.mandatory-reimbursement-file.label')}
                </Form.Label>
                {tiersPayant.mandatoryReimbursementFile && (
                    <div>
                        Fichier téléchargé: <a target="_blank"
                                                href={`${apiURL}/api/patient-file/${tiersPayant.invoiceFile.id}?token=${token}`}>
                        {tiersPayant.mandatoryReimbursementFile.name}</a>
                        {!showMandatoryReimbursementFile && (
                            <div><a style={{color: '#4287f5'}}
                                    onClick={() => setShowMandatoryReimbursementFile(!showMandatoryReimbursementFile)}>Le modifier ?</a></div>)}
                    </div>
                )}

                {showMandatoryReimbursementFile && (
                    <>
                        {hasMandatoryReimbursementFile && <>
                            <Form.Control
                                type="file"
                                size="lg"
                                {...register("mandatoryReimbursementFile", {
                                    required: 'error-empty',
                                })}
                                disabled={loading}
                                isInvalid={formErrors && formErrors.mandatoryReimbursementFile}
                            />
                            {formErrors && formErrors.mandatoryReimbursementFile && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`tiers-payant.manage.form.mandatory-reimbursement-file.errors.${formErrors.mandatoryReimbursementFile.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </>}
                        <Form.Check // prettier-ignore
                            type="checkbox"
                            id="hasMandatoryReimbursementFile"
                            size="lg"
                            label={t('tiers-payant.manage.form.has-mandatory-reimbursement-file.label')}
                            {...register("hasMandatoryReimbursementFile")}
                            disabled={loading}
                            onChange={val => {
                                setHasMandatoryReimbursementFile(!val.target.checked)
                            }}
                        />

                    </>)}
            </Form.Group>

            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    {t('tiers-payant.manage.button.cancel')}
                </Button>
                <Button variant="primary" type="submit">
                    {t('tiers-payant.manage.button.next')}
                </Button>
            </Modal.Footer>
        </Form>
    )
}

export default Step4AdditionalDocument