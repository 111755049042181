import {Button, Col, Modal, Nav, Row, Tab, Tabs} from "react-bootstrap";
import TiersPayantStatusBadge from "../../components/TiersPayantStatutBadge";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useTiersPayant} from "../../context/TiersPayantContext";
import {Link, Route, Routes, useNavigate, useParams, useSearchParams} from "react-router-dom";
import ManageTiersPayant from "./ManageTiersPayant";
import CommentTiersPayant from "./CommentTiersPayant";
import RestartRefundTiersPayant from "./RestartRefundTiersPayant";
import UpdateTiersPayant from "./UpdateTiersPayant";
import {useAuth} from "../../context/AuthContext";
import PatientFiles from "./PatientFiles";

const ShowTiersPayant = ({tabsName}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [tiersPayant, setTiersPayant] = useState(0)
    const {tiersPayants, resetWorkflow} = useTiersPayant()
    const [listParams, setListParams] = useState(null)
    const [showUpdateTab, setShowUpdateTab] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    let { id } = useParams();
    const {hasRight} = useAuth()

    useEffect(() => {
        if (tiersPayants) {
            setTiersPayant(tiersPayants.find(tiersPayant => tiersPayant.id === parseInt(id)))
        }
    }, [tiersPayants]);

    useEffect(() => {
        const customerId = searchParams.get('customerId');
        const date = searchParams.get('date');
        const status = searchParams.get('status');
        const page = searchParams.get('page');
        const query = searchParams.get('query');

        const params = []
        if (customerId) {
            params['customerId'] = customerId
        }

        if (date) {
            params['date'] = date
        }
        if (status) {
            params['status'] = status
        }
        if (page) {
            params['page'] = page
        }
        if (query) {
            params['query'] = query
        }

        if (Object.keys(params).length > 0) {
            const listParamsString = Object.keys(params).map((key) => {
                return (`${key}=${params[key]}`)
            }).join('&')
            setListParams(listParamsString)
        } else {
            setListParams(null)
        }
    }, []);

    const handleResetWorkflow = () => {
        resetWorkflow(tiersPayant.id)
    }

    const handleClose = () => {
        if (listParams) {
            navigate(`/tiers-payant?${listParams}`)
        } else {
            navigate('/tiers-payant')
        }
    }

    if (!tiersPayant) {
        return <></>
    }

    const sentAt = tiersPayant.sentAt ? new Date( tiersPayant.sentAt) : null
    const restartSendAt = tiersPayant.restartSendAt ? new Date( tiersPayant.restartSendAt) : null
    const paidAt = tiersPayant.paymentDate ? new Date( tiersPayant.paymentDate) : (tiersPayant.paidAt ? new Date( tiersPayant.paidAt) : null)

    return (
        <Modal
            show={true}
            onHide={handleClose}
            size="xl"
        >
            {tiersPayant && <>
                <Modal.Header closeButton>
                <Modal.Title>
                    <TiersPayantStatusBadge status={tiersPayant.status} /><br/>
                    {t('tiers-payant.manage.title')} - {tiersPayant.patientName} - {tiersPayant?.customer?.name}
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <h4>Facture - {tiersPayant.invoiceNumber}</h4>
                            <dl>
                                <dt><strong>N°FSE:</strong> {tiersPayant.fseCode}</dt>
                                <dt><strong>NIR:</strong> {tiersPayant.nirCode}</dt>
                                <dt>
                                    <i className="fa-solid fa-calendar-days"></i> {tiersPayant.invoiceDate}
                                </dt>
                                {tiersPayant.type && <dt><strong>Type de dossier:</strong> {t(`tiers-payant.type.${tiersPayant.type}`)}</dt>}
                            </dl>
                        </Col>
                        <Col>
                            <h4>{tiersPayant.remainingAmount}€</h4>
                            <dl>
                                <dt><i className="fa-solid fa-money-check-dollar"
                                       style={{fontSize: '12px'}}></i> {tiersPayant.healthInsurance.name}</dt>
                                {tiersPayant.calculatedSendingType && <dt>
                                    {t(`tiers-payant.manage.form.sending-type.${tiersPayant.calculatedSendingType}`)}
                                </dt>}
                                <dt>&nbsp;</dt>
                            </dl>
                        </Col>
                        <Col>
                            <dl>
                                {hasRight('tiers_payant_reset') && tiersPayant.hasManageTab === false &&
                                    <dt style={{marginBottom: "10px"}}><Button onClick={() => handleResetWorkflow()}>Réinitialiser le process</Button></dt>}
                                {hasRight('tiers_payant_update') && showUpdateTab === false &&
                                    <dt><Button variant="secondary" onClick={() => setShowUpdateTab(true)}>Édit dossier</Button></dt>}
                            </dl>
                        </Col>
                    </Row>
                    <dl style={{textAlign: "right"}}>
                        {tiersPayant.status === 'closed' && <dt style={{color: '#495057'}}>
                            Le dossier a été clôturé {sentAt ? ` le ${sentAt.toLocaleDateString()}` : null}{tiersPayant.sentBy ? ` par ${tiersPayant.sentBy.firstName} ${tiersPayant.sentBy.lastName}` : null}.
                        </dt>}
                        {(tiersPayant.sentAt !== null && tiersPayant.sentAt !== "" && tiersPayant.sentAt !== undefined && tiersPayant.status !== 'closed') && <dt style={{color: '#4BBF73'}}>
                            Le dossier a été envoyé {sentAt ? ` le ${sentAt.toLocaleDateString()}` : null}{tiersPayant.sentBy ? ` par ${tiersPayant.sentBy.firstName} ${tiersPayant.sentBy.lastName}` : null}.
                        </dt>}
                        {(tiersPayant.restartSendAt !== null && tiersPayant.restartSendAt !== "" && tiersPayant.restartSendAt !== "" && tiersPayant.status !== 'closed' && tiersPayant.status !== 'closed') && <dt style={{color: '#FFC107'}}>
                            Le dossier a été relancé
                            {tiersPayant.restartSendingType ? ` (${t(`health-insurance.sendingType.${tiersPayant.restartSendingType}`)})` : null}
                            {restartSendAt ? ` le ${restartSendAt.toLocaleDateString()}` : null}{tiersPayant.restartSentBy ? ` par ${tiersPayant.restartSentBy.firstName} ${tiersPayant.restartSentBy.lastName}` : null}.
                        </dt>}
                        {(paidAt && (tiersPayant.status === 'paid' || tiersPayant.status === 'partially_paid')) && <dt style={{color: '#4BBF73'}}>
                            Le dossier a été marqué payé {paidAt ? ` le ${paidAt.toLocaleDateString()}` : null}.
                        </dt>}
                    </dl>
                    <Tab.Container activeKey={tabsName}>
                        <Nav className="mb-4 nav-tabs">
                            <Nav.Item>
                                <Nav.Link eventKey="comment" as={Link} to={`/tiers-payant/comment/${id}${listParams ? `?${listParams}` : ''}`}>Commentaires</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="patient_files" as={Link} to={`/tiers-payant/patient-files/${id}${listParams ? `?${listParams}` : ''}`}>Documents patient</Nav.Link>
                            </Nav.Item>
                            {tiersPayant.hasManageTab === true && <Nav.Item>
                                <Nav.Link eventKey="manage" as={Link} to={`/tiers-payant/manage/${id}${listParams ? `?${listParams}` : ''}`}>Gestion du dossier</Nav.Link>
                            </Nav.Item>}
                            {tiersPayant.hasRestartRefundTab === true && <Nav.Item>
                                <Nav.Link eventKey="restart_refund" as={Link} to={`/tiers-payant/restart_refund/${id}${listParams ? `?${listParams}` : ''}`}>Relance</Nav.Link>
                            </Nav.Item>}
                            {showUpdateTab && <Nav.Item>
                                <Nav.Link eventKey="update" as={Link} to={`/tiers-payant/update/${id}${listParams ? `?${listParams}` : ''}`}>Modifier</Nav.Link>
                            </Nav.Item>}
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="comment">
                                <CommentTiersPayant handleClose={handleClose} tiersPayant={tiersPayant} />
                            </Tab.Pane >
                            <Tab.Pane eventKey="patient_files">
                                <PatientFiles handleClose={handleClose} tiersPayant={tiersPayant} />
                            </Tab.Pane >
                            {tiersPayant.hasManageTab === true && <Tab.Pane eventKey="manage">
                                <ManageTiersPayant handleClose={handleClose} />
                            </Tab.Pane >}
                            {tiersPayant.hasRestartRefundTab === true && <Tab.Pane eventKey="restart_refund">
                                <RestartRefundTiersPayant handleClose={handleClose} tiersPayant={tiersPayant} />
                            </Tab.Pane >}
                            {showUpdateTab && <Tab.Pane eventKey="update">
                                <UpdateTiersPayant handleClose={handleClose} tiersPayant={tiersPayant} />
                            </Tab.Pane>}
                        </Tab.Content>
                    </Tab.Container>
                </Modal.Body>
            </>}
        </Modal>
    )
}

export default ShowTiersPayant