import React, {useEffect, useState} from "react";
import {useOutletContext, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useTiersPayant} from "../../context/TiersPayantContext";
import Stepper from "../../components/Stepper";
import Step1Document from "./steps/Step1Document";
import Step2Verification from "./steps/Step2Verification";
import Step3Validation from "./steps/Step3Validation";
import Step4AdditionalDocument from "./steps/Step4AdditionalDocument";
import Step5Cancellation from "./steps/Step5Cancellation";
import Step6ConfirmationAction from "./steps/Step6ConfirmationAction";
import Step7CancellationCare from "./steps/Step7CancellationCare";

const ManageTiersPayant = ({handleClose}) => {
    const { t } = useTranslation();
    const [canClose, setCanClose] = useState(false)
    const [step, setStep] = useState(0)
    const [stepName, setStepName] = useState('documents')
    const [stepsConfig, setStepsConfig] = useState([])
    const [tiersPayant, setTiersPayant] = useState(0)
    const {tiersPayants, fetchAll} = useTiersPayant()
    let { id } = useParams();

    const {setSuccessMessage} = useOutletContext()
    const {loading, apiErrors, step1Document, step2Verification, step3Validation, step4AdditionalDocument, step5Cancellation, step6ConfirmationAction, step7CancellationCare, load} = useTiersPayant()

    useEffect(() => {
        if (tiersPayants) {
            setSuccessMessage(null)
            setTiersPayant(tiersPayants.find(tiersPayant => tiersPayant.id === parseInt(id)))
        }
    }, [tiersPayants]);

    useEffect(() => {
        if (tiersPayant) {
            setStep(tiersPayant.currentStep)
            setStepName(tiersPayant.steps[tiersPayant.currentStep])

            const initStepsConfig = []

            Object.keys(tiersPayant.steps).map((key) => {
                initStepsConfig.push({
                    name: t(`tiers-payant.step.title.${tiersPayant.steps[key]}`),
                    isClickable: (key) <= tiersPayant.currentStep,
                })
            })

            setStepsConfig(initStepsConfig)
        }
    }, [tiersPayant]);

    const onClickStep = (stepNumber) => {
        setStep(stepNumber)
        setStepName(tiersPayant.steps[stepNumber])
    }

    useEffect(() => {
        setSuccessMessage(null)
    }, []);

    useEffect(() => {
        if (canClose && !loading && !apiErrors) {
            setSuccessMessage(t('tiers-payant.manage.success_message'))
            handleClose()
        }
    }, [canClose, loading, apiErrors]);

    const onSubmitStep1 = async (inputs) => {
        load()
        await step1Document(id, inputs)
    }

    const onSubmitStep2 = async (inputs) => {
        load()
        await step2Verification(id, inputs)
    }

    const onSubmitStep3 = async (inputs) => {
        await step3Validation(id, inputs)
    }

    const onSubmitStep4 = async (inputs) => {
        load()
        await step4AdditionalDocument(id, inputs)
    }

    const onSubmitStep5 = async (inputs) => {
        load()
        await step5Cancellation(id, inputs)
    }

    const onSubmitStep6 = async (inputs) => {
        load()
        await step6ConfirmationAction(id, inputs)
    }

    const onSubmitStep7 = async (inputs) => {
        load()
        await step7CancellationCare(id, inputs)
    }

    return (
        <>
            <Stepper stepsConfig={stepsConfig} currentStep={step} onClickStep={onClickStep} />
            {stepName === 'documents' && <Step1Document
                tiersPayant={tiersPayant}
                loading={loading}
                handleClose={handleClose}
                onSubmit={onSubmitStep1}
                apiErrors={apiErrors}
            />}
            {stepName === 'verification' && <Step2Verification
                tiersPayant={tiersPayant}
                loading={loading}
                handleClose={handleClose}
                onSubmit={onSubmitStep2}
                apiErrors={apiErrors}
            />}
            {stepName === 'validation' && <Step3Validation
                tiersPayant={tiersPayant}
                loading={loading}
                handleClose={handleClose}
                onSubmit={onSubmitStep3}
                apiErrors={apiErrors}
            />}
            {stepName === 'additional_documents' && <Step4AdditionalDocument
                tiersPayant={tiersPayant}
                loading={loading}
                handleClose={handleClose}
                onSubmit={onSubmitStep4}
                apiErrors={apiErrors}
            />}
            {stepName === 'cancellation' && <Step5Cancellation
                tiersPayant={tiersPayant}
                loading={loading}
                handleClose={handleClose}
                onSubmit={onSubmitStep5}
                apiErrors={apiErrors}
            />}
            {stepName === 'confirmation_action' && <Step6ConfirmationAction
                tiersPayant={tiersPayant}
                loading={loading}
                handleClose={handleClose}
                onSubmit={onSubmitStep6}
                apiErrors={apiErrors}
            />}
            {stepName === 'cancellation_care' && <Step7CancellationCare
                tiersPayant={tiersPayant}
                loading={loading}
                handleClose={handleClose}
                onSubmit={onSubmitStep7}
                apiErrors={apiErrors}
            />}
        </>
    )
}

export default ManageTiersPayant