import {createContext, useContext} from "react";
import {useTiersPayants} from "../hooks/api/tiersPayant";

const TiersPayantContext = createContext();

const TiersPayantProvider = ({children}) => {
    const [{tiersPayants, metaData, importStats, apiErrors, loading}, {fetchAll, add, update, addPatientFile, resetWorkflow, importFile, document, deleteDocument, createComment, step1Document, step2Verification, step3Validation, step4AdditionalDocument, step5Cancellation, step6ConfirmationAction, step7CancellationCare, restartRefund, resetApiErrors, load}] = useTiersPayants()

    return <TiersPayantContext.Provider value={{ tiersPayants, metaData, importStats, apiErrors, loading, fetchAll, add, update, addPatientFile, resetWorkflow, importFile, document, deleteDocument, createComment, step1Document, step2Verification, step3Validation, step4AdditionalDocument, step5Cancellation, step6ConfirmationAction, step7CancellationCare, restartRefund, resetApiErrors, load }}>
        {children}
    </TiersPayantContext.Provider>;
}

export default TiersPayantProvider

export const useTiersPayant = () => {
    return useContext(TiersPayantContext);
};