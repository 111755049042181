import {Alert, Button, Form, Modal, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";


const Step6ConfirmationAction = ({tiersPayant, loading, handleClose, onSubmit}) => {
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
        control,
        setValue,
    } = useForm({})

    const validateSubmit = ({actionIsConfirmed}) => {
        const actionIsConfirmedBool = actionIsConfirmed === null ? null : (actionIsConfirmed === 'true');

        onSubmit({actionIsConfirmed: actionIsConfirmedBool})
    }

    useEffect(() => {
        if (tiersPayant) {
            if (tiersPayant.actionIsConfirmed !== null) {
                setValue('actionIsConfirmed', (tiersPayant.actionIsConfirmed === true ? 'true' : 'false'))
            }
        }
    }, [tiersPayant]);


    return(
        <Form onSubmit={handleSubmit(validateSubmit)}>
            <Alert variant="info">
                <div className="alert-message">
                    <span className="block sm:inline">
                        {tiersPayant.calculatedSendingType === 'letter' && <>
                            Pour un envoi par courrier, vous devez imprimer tous les documents et les envoyer par voie Portale.
                        </>}
                        {tiersPayant.calculatedSendingType === 'teletransmission' && <>
                            Merci de vérifier que le dossier a été bien facturé avec une part DRE (demande de remboursement électronique) dans le logiciel du client.
                        </>}
                        {(tiersPayant.calculatedSendingType === 'web_portal' || tiersPayant.calculatedSendingType === 'web_mail') && <>
                            Vous devez vous connecter dans le portail WEB de la mutuelle pour faire la demande de remboursement.
                        </>}
                    </span>
                </div>
            </Alert>
            <Form.Group className="mb-3" controlId="tiers-payant.action-is-confirmed">
                <Form.Label>{t('tiers-payant.manage.form.action-is-confirmed.label')}</Form.Label>
                <Controller
                    name="actionIsConfirmed"
                    control={control}
                    render={({ field, value, name, ref }) => (
                        <ToggleButtonGroup
                            type="radio"
                            value={field.value}
                            onChange={(val) => {
                                field.onChange(val)
                            }}
                            name={field.name}
                            ref={field.ref}
                            style={{marginLeft: '10px'}}
                        >
                            <ToggleButton id="actionIsConfirmed-btn-1" value='true' variant='outline-info'>Oui</ToggleButton>
                            <ToggleButton id="actionIsConfirmed-btn-2" value='false' variant='outline-info'>Non</ToggleButton>
                        </ToggleButtonGroup>
                    )}
                    size="lg"
                    {...register("actionIsConfirmed", {
                        required: 'error-empty',
                    })}
                />
            </Form.Group>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    {t('tiers-payant.manage.button.cancel')}
                </Button>
                <Button variant="primary" type="submit">
                    {t('tiers-payant.manage.button.next')}
                </Button>
            </Modal.Footer>
        </Form>
    )
}

export default Step6ConfirmationAction